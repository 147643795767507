import './Shop.scss';
import { ButtonGroup, Button, Col, Container, Row, Form, Alert } from 'react-bootstrap';
import { FaCartArrowDown } from 'react-icons/fa';
import { BiArrowBack } from 'react-icons/bi';
import { FiMinusSquare, FiPlusSquare } from 'react-icons/fi';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import stickerone from './holosticker1.png';
import stickertwo from './holosticker2.png';
import stickerthree from './holosticker3.png';
import stickerfour from './holosticker4.png';
import stickerfive from './holosticker5.png';
import stickersix from './holosticker6.png';
import stickerseven from './holosticker7.png';
import stickereight from './holosticker8.png';
import stickernine from './holosticker9.png';
import stickerten from './holosticker10.png';
import stickereleven from './holosticker11.png';
import stickertwelve from './holosticker12.png';
import stickerthirteen from './holosticker13.png';
import stickerfourteen from './holosticker14.png';
import stickerfifteen from './holosticker15.png';
import stickersixteen from './holosticker16.png';
import stickerseventeen from './roundsticker1.png';
import stickereighteen from './sticker1.png';
import printone from './print1.png';
import printtwo from './print2.png';
import printthree from './print3.png';
import printfour from './print4.png';
import printfive from './print5.png';
import printsix from './print6.png';
import printseven from './print7.png';
import printeight from './print8.png';
import printnine from './print9.png';
import printten from './print10.png';
import printeleven from './print11.png';
import printtwelve from './print12.png';
import printthirteen from './print13.png';
import printfourteen from './print14.png';
import printfifteen from './print15.png';
import printsixteen from './print16.png';
import printseventeen from './print17.png';
import printeighteen from './print18.png';
import printnineteen from './print19.png';
import printtwenty from './print20.png';
import printtwentyone from './print21.png';
import printtwentytwo from './print22.png';
import printtwentythree from './print23.png';
import printtwentyfour from './print24.png';
import printtwentyfive from './print25.png';
import printtwentysix from './print26.png';
import keychainone from './keychain1.png';
import pinone from './pin1.png';
import pintwo from './pin2.png';
import magnetone from './magnet1.png';
import coasterone from './coaster1.png';
import coastertwo from './coaster2.png';
import buttonone from './button1.png';
import buttontwo from './button2.png';
import buttonthree from './button3.png';
import buttonfour from './button4.png';

import ItemPage from './ItemPage';

export default function Shop (props) {

    function handleBackClick (event) {
        const fullitems = genItems();
        setItemsjsx(fullitems);
    }
    
    const [curritemjsx, setCurritemjsx] = useState('')
    function handleItemClick (event) {
        const itemindex = event.currentTarget.dataset.id;
        let newpagejsx = <div>
                <Button variant='info' className='backbutton' onClick={handleBackClick}><BiArrowBack /> BACK</Button>
                <ItemPage itemdata={props.database[itemindex]} itemindex={itemindex} cart={props.cart} photo={props.database[itemindex]['photo']} />
            </div>
        setItemsjsx(newpagejsx)
    }

    const [newalert, setNewalert] = useState('')
    const [show, setShow] = useState(true)

    function hideAlert () {
        setNewalert('')
    }


    function handleAddToCart (event) {
        const itemindex = event.currentTarget.dataset.id;
        const quantity = 1;
        props.cart.push([itemindex, quantity])

        setNewalert(
            <div>
                <SweetAlert
                    success
                    title="Added to Cart"
                    onConfirm={hideAlert}
                    >
                </SweetAlert>
            </div>
            )
        
    }

    const [checkbooldict, setCheckbooldict] = useState({
        sticker: true,
        print: true,
        button: true,
        pin: true,
        magnet: true,
        keychain: true,
        coaster: true,
        cheap: true,
        midprice: true,
        expensive: true,
    })
    function handleCheckBtnChange (event) {
        const currkey = event.currentTarget.dataset.id;
        checkbooldict[currkey] = !checkbooldict[currkey];
        setItemsjsx(genItems());
    }


    const [quantity, setQuantity] = useState(1);
    function handleMinusQuantity (event) {

    }

    function handlePlusQuantity (event) {

    }
    
    function genItems () {
        const databaselist = Object.entries(props.database)
        let newjsx = [];
        let currjsx = [];
        for (let i=0; i < databaselist.length; i++) {
            if (i % 3 === 0 && i > 0) {
                newjsx.push(<Row>
                    {currjsx}
                </Row>)
                currjsx = [];
            }

            const catbool = checkbooldict[databaselist[i][1]['category']] === true;
            const itemprice = databaselist[i][1]['price']
            let priceboollist = [];
            const pricelist = ['cheap', 'midprice', 'expensive']
            for (let j=0; j < pricelist.length; j++) {
                if (checkbooldict[pricelist[j]] === true) {
                    if (pricelist[j] === 'cheap') {
                        if (itemprice === 2) {
                            priceboollist.push(true);
                        } else {
                            priceboollist.push(false)
                        }
                    } else if (pricelist[j] === 'midprice') {
                        if (itemprice === 3) {
                            priceboollist.push(true);
                        } else {
                            priceboollist.push(false);
                        }
                    } else if (pricelist[j] === 'expensive') {
                        if (itemprice === 4) {
                            priceboollist.push(true);
                        } else {
                            priceboollist.push(false);
                        }
                    }
                } else {
                    priceboollist.push(false)
                }
            }
            const pricebool = priceboollist[0] || priceboollist[1] || priceboollist[2]

            if (catbool && pricebool) {
                currjsx.push(<Col className='itemforsale'>
                        <Button data-id={databaselist[i][0]} onClick={handleItemClick} className='specificimgbtn' >
                            <img className='specificimg' src={databaselist[i][1]['photo']} />
                        </Button>
                        <div className='itemtext'>
                            <Container>
                                <Row className='pricedimrow'>
                                    <Col sm={6}>
                                        <span className='dimensions'>{databaselist[i][1]['width']}" x {databaselist[i][1]['height']}"</span>
                                    </Col>
                                    <Col sm={2}>
                                        <p className='price'>${databaselist[i][1]['price']}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className='itemtype text-center'>{databaselist[i][1]['type']+ ' ' + databaselist[i][1]['category']}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='changequantityshop'><Button variant='danger' onClick={handleMinusQuantity} className='minusbuttonshop'><FiMinusSquare /></Button><span className='quantityshop'>{quantity}</span><Button variant='danger' onClick={handlePlusQuantity} className='plusbuttonshop'><FiPlusSquare /></Button></div>
                                    </Col>
                                    <Col>
                                        <Button variant='info' data-id={databaselist[i][0]} onClick={handleAddToCart} className='addtocartbutton' ><FaCartArrowDown /></Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>)
                }
        }
        if (currjsx !== []) {
            newjsx.push(<Row>
                {currjsx}
            </Row>)
        }
        const outjsx = <Container>
            <Row>
                <Col sm={1}>
                    <div className='filter'>
                        <p>Filter by...</p>
                        <div className='categoryfilter'>
                            <p>Category:</p>
                            <Form>
                                <Form.Check 
                                    type="switch"
                                    id="sticker"
                                    data-id="sticker"
                                    label="Stickers"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["sticker"]}
                                />
                                <Form.Check 
                                    type="switch"
                                    id="print"
                                    data-id="print"
                                    label="Prints"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["print"]}
                                />
                                <Form.Check 
                                    type="switch"
                                    id="button"
                                    data-id="button"
                                    label="Buttons"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["button"]}
                                />
                                <Form.Check 
                                    type="switch"
                                    id="pin"
                                    data-id="pin"
                                    label="Pins"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["pin"]}
                                />
                                <Form.Check 
                                    type="switch"
                                    id="magnet"
                                    data-id="magnet"
                                    label="Magnets"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["magnet"]}
                                />
                                <Form.Check 
                                    type="switch"
                                    id="keychain"
                                    data-id="keychain"
                                    label="Keychain"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["keychain"]}
                                />
                                <Form.Check 
                                    type="switch"
                                    id="coaster"
                                    data-id="coaster"
                                    label="Coasters"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["coaster"]}
                                />
                            </Form>
                        </div>
                        <div className='pricefilter'>
                            <p>Price:</p>
                            <Form>
                                <Form.Check 
                                    type="switch"
                                    id="cheap"
                                    data-id="cheap"
                                    label="$2"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["cheap"]}
                                />
                            </Form>
                            <Form>
                                <Form.Check 
                                    type="switch"
                                    id="midprice"
                                    data-id="midprice"
                                    label="$3"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["midprice"]}
                                />
                            </Form>
                            <Form>
                                <Form.Check 
                                    type="switch"
                                    id="expensive"
                                    data-id="expensive"
                                    label="$4"
                                    onChange={handleCheckBtnChange}
                                    checked={checkbooldict["expensive"]}
                                />
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col sm={10}>
                    {newjsx}
                </Col>
            </Row>
        </Container>
        return outjsx
    }

    const [itemsjsx, setItemsjsx] = useState(genItems())
    
    return (
        <div>
            <h1 className='text-center shopbyitemtitle'>Shop by Item</h1>
            {itemsjsx}
            {newalert}
        </div>
    )
}