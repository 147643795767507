import { useState, useRef } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { FiMinusSquare, FiPlusSquare } from 'react-icons/fi';
import { IoBagCheckOutline } from 'react-icons/io5';
import './Cart.scss';

import stickerone from '../Shop/holosticker1.png';
import stickertwo from '../Shop/holosticker2.png';
import stickerthree from '../Shop/holosticker3.png';
import stickerfour from '../Shop/holosticker4.png';
import stickerfive from '../Shop/holosticker5.png';
import stickersix from '../Shop/holosticker6.png';
import stickerseven from '../Shop/holosticker7.png';
import stickereight from '../Shop/holosticker8.png';
import stickernine from '../Shop/holosticker9.png';
import stickerten from '../Shop/holosticker10.png';
import stickereleven from '../Shop/holosticker11.png';
import stickertwelve from '../Shop/holosticker12.png';
import stickerthirteen from '../Shop/holosticker13.png';
import stickerfourteen from '../Shop/holosticker14.png';
import stickerfifteen from '../Shop/holosticker15.png';
import stickersixteen from '../Shop/holosticker16.png';
import stickerseventeen from '../Shop/roundsticker1.png';
import stickereighteen from '../Shop/sticker1.png';
import printone from '../Shop/print1.png';
import printtwo from '../Shop/print2.png';
import printthree from '../Shop/print3.png';
import printfour from '../Shop/print4.png';
import printfive from '../Shop/print5.png';
import printsix from '../Shop/print6.png';
import printseven from '../Shop/print7.png';
import printeight from '../Shop/print8.png';
import printnine from '../Shop/print9.png';
import printten from '../Shop/print10.png';
import printeleven from '../Shop/print11.png';
import printtwelve from '../Shop/print12.png';
import printthirteen from '../Shop/print13.png';
import printfourteen from '../Shop/print14.png';
import printfifteen from '../Shop/print15.png';
import printsixteen from '../Shop/print16.png';
import printseventeen from '../Shop/print17.png';
import printeighteen from '../Shop/print18.png';
import printnineteen from '../Shop/print19.png';
import printtwenty from '../Shop/print20.png';
import printtwentyone from '../Shop/print21.png';
import printtwentytwo from '../Shop/print22.png';
import printtwentythree from '../Shop/print23.png';
import printtwentyfour from '../Shop/print24.png';
import printtwentyfive from '../Shop/print25.png';
import printtwentysix from '../Shop/print26.png';
import keychainone from '../Shop/keychain1.png';
import pinone from '../Shop/pin1.png';
import pintwo from '../Shop/pin2.png';
import magnetone from '../Shop/magnet1.png';
import coasterone from '../Shop/coaster1.png';
import coastertwo from '../Shop/coaster2.png';
import buttonone from '../Shop/button1.png';
import buttontwo from '../Shop/button2.png';
import buttonthree from '../Shop/button3.png';
import buttonfour from '../Shop/button4.png';
import Checkout from '../Checkout/Checkout';


export default function Cart (props) {
    console.log(props.cart)
    let initialprice = 0
    for (let i=0; i < props.cart.length; i++) {
        initialprice += props.database[props.cart[i][0]]['price'] * props.cart[i][1]
    }
    const [totalprice, setTotalPrice] = useState(initialprice)
    const totalpriceRef = useRef(totalprice);

    function handleMinusQuantity (event) {
        const cartindex = event.currentTarget.dataset.id;
        const itemdata = props.database[props.cart[cartindex][0]]
        console.log(cartindex)
        props.cart[cartindex][1] -= 1;
        totalpriceRef.current = totalpriceRef.current - itemdata['price']
        setCartjsx(genCartjsx())
    }

    function handlePlusQuantity (event) {
        const cartindex = event.currentTarget.dataset.id;
        const itemdata = props.database[props.cart[cartindex][0]]
        props.cart[cartindex][1] += 1;
        totalpriceRef.current = totalpriceRef.current + itemdata['price']
        setCartjsx(genCartjsx())
    }

    function genCartjsx () {
        const initialcartjsx = [];
        initialcartjsx.push(<h1 className='text-center'>Shopping Cart</h1>)
        initialcartjsx.push(
            <Row>
                <Col>
                    Photo
                </Col>
                <Col>
                    Description
                </Col>
                <Col>
                    Category
                </Col>
                <Col>
                    Unit Price
                </Col>
                <Col>
                    Quantity
                </Col>
                <Col>
                    Item Price
                </Col>
            </Row>
        )

        //
        for (let i=0; i < props.cart.length; i++) {
            const itemindex = props.cart[i][0]
            const itemdata  = props.database[itemindex]
            //setTotalPrice(totalprice)
            initialcartjsx.push(
                <Row className='cartrow'>
                    <Col>
                        <Image src={itemdata['photo']} className='cartimage' />
                    </Col>
                    <Col>
                        {itemdata['name']}
                    </Col>
                    <Col>
                        {itemdata['type'] + ' ' + itemdata['category']}
                    </Col>
                    <Col>
                        {'$'+ itemdata['price']}
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Button variant='info' data-id={i} onClick={handleMinusQuantity} className='minusbutton'>
                                    <FiMinusSquare />
                                </Button>
                            </Col>
                            <Col>
                                {props.cart[i][1]}
                            </Col>
                            <Col>
                                <Button variant='info' data-id={i} onClick={handlePlusQuantity} className='minusbutton'>
                                    <FiPlusSquare />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {'$'+ itemdata['price'] * props.cart[i][1]}
                    </Col>
                </Row>
            )
        }
        initialcartjsx.push(<Row>
            <Col>
                <p className='totalpricecart'>Total Price: ${totalpriceRef.current}</p>
            </Col>
        </Row>)
        initialcartjsx.push(<Button onClick={handleCheckout} variant='success' className='checkoutbutton'><span className='checkouticon'><IoBagCheckOutline /></span>Checkout</Button>)
        return initialcartjsx
    }

    const [cartjsx, setCartjsx] = useState(genCartjsx());

    function handleCheckout (event) {
        setCartjsx(<Checkout />)
    }

    return (
        <div className='cart'>
            <Container>
                {cartjsx}
            </Container>
        </div>
    )
}