import './App.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


function App() {

  return (
    <div className='aliengazeboapp'>
      <Header />
      <Footer />
    </div>
  );
}

export default App;
