import './Footer.scss';
import { BiCopyright } from 'react-icons/bi';

function Footer () {
    return (
        <div className='footerbody'>
            <p className='text-center footertext'><BiCopyright /> 2021 Sergi Ferrer</p>
            <p className='text-center footertext'>Web Design by L. Scott Storch</p>
        </div>
    )
}

export default Footer;