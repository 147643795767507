import './Header.scss';
import { Col, Container, Nav, Navbar, NavDropdown, Row, Image} from 'react-bootstrap';
import aliengazebologo from './aliengazeboicon.png';
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Shop from '../Shop/Shop';
import Home from '../Home/Home';
import Contact from '../Contact/Contact';
import Cart from '../Cart/Cart';
import { FaShoppingCart } from 'react-icons/fa';
import { IoBagCheckOutline } from 'react-icons/io5';
import { useState } from 'react';

import stickerone from '../Shop/holosticker1.png';
import stickertwo from '../Shop/holosticker2.png';
import stickerthree from '../Shop/holosticker3.png';
import stickerfour from '../Shop/holosticker4.png';
import stickerfive from '../Shop/holosticker5.png';
import stickersix from '../Shop/holosticker6.png';
import stickerseven from '../Shop/holosticker7.png';
import stickereight from '../Shop/holosticker8.png';
import stickernine from '../Shop/holosticker9.png';
import stickerten from '../Shop/holosticker10.png';
import stickereleven from '../Shop/holosticker11.png';
import stickertwelve from '../Shop/holosticker12.png';
import stickerthirteen from '../Shop/holosticker13.png';
import stickerfourteen from '../Shop/holosticker14.png';
import stickerfifteen from '../Shop/holosticker15.png';
import stickersixteen from '../Shop/holosticker16.png';
import stickerseventeen from '../Shop/roundsticker1.png';
import stickereighteen from '../Shop/sticker1.png';
import printone from '../Shop/print1.png';
import printtwo from '../Shop/print2.png';
import printthree from '../Shop/print3.png';
import printfour from '../Shop/print4.png';
import printfive from '../Shop/print5.png';
import printsix from '../Shop/print6.png';
import printseven from '../Shop/print7.png';
import printeight from '../Shop/print8.png';
import printnine from '../Shop/print9.png';
import printten from '../Shop/print10.png';
import printeleven from '../Shop/print11.png';
import printtwelve from '../Shop/print12.png';
import printthirteen from '../Shop/print13.png';
import printfourteen from '../Shop/print14.png';
import printfifteen from '../Shop/print15.png';
import printsixteen from '../Shop/print16.png';
import printseventeen from '../Shop/print17.png';
import printeighteen from '../Shop/print18.png';
import printnineteen from '../Shop/print19.png';
import printtwenty from '../Shop/print20.png';
import printtwentyone from '../Shop/print21.png';
import printtwentytwo from '../Shop/print22.png';
import printtwentythree from '../Shop/print23.png';
import printtwentyfour from '../Shop/print24.png';
import printtwentyfive from '../Shop/print25.png';
import printtwentysix from '../Shop/print26.png';
import keychainone from '../Shop/keychain1.png';
import pinone from '../Shop/pin1.png';
import pintwo from '../Shop/pin2.png';
import magnetone from '../Shop/magnet1.png';
import coasterone from '../Shop/coaster1.png';
import coastertwo from '../Shop/coaster2.png';
import buttonone from '../Shop/button1.png';
import buttontwo from '../Shop/button2.png';
import buttonthree from '../Shop/button3.png';
import buttonfour from '../Shop/button4.png';
import Checkout from '../Checkout/Checkout';

function Header () {
    const database = {
        1: {
            name: 'tiger',
            category: 'sticker',
            type: 'holographic',
            photo: stickerone,
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
            },
        2: {name: 'skull',
            photo: stickertwo,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
            },
        3: {name: 'cross',
            photo: stickerthree,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        4: {name: 'smiling sun',
            photo: stickerfour,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        5: {name: 'astronaut',
            photo: stickerfive,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        6: {name: 'mirrored design',
            photo: stickersix,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        7: {name: 'tropical leaves',
            photo: stickerseven,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        8: {name: 'jesus',
            photo: stickereight,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        9: {name: 'baboon',
            photo: stickernine,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        10: {name: 'humanoid vision',
            photo: stickerten,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        11: {name: 'christ with cross',
            photo: stickereleven,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        12: {name: 'mystic hat woman',
            photo: stickertwelve,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        13: {name: 'bulldog',
            photo: stickerthirteen,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        14: {name: 'humanoid vision',
            photo: stickerfourteen,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        15: {name: 'skater',
            photo: stickerfifteen,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        16: {name: 'blue-green design',
            photo: stickersixteen,
            category: 'sticker',
            type: 'holographic',
            height: 3.6,
            width: 2.7,
            price: 4,
            currentstock: 10,
            date: '',
        },
        17: {name: 'cosmic',
            photo: stickerseventeen,
            category: 'sticker',
            type: 'round',
            height: 3,
            width: 3,
            price: 2,
            currentstock: 10,
            date: '',
        },
        18: {name: 'abstract void',
            photo: stickereighteen,
            category: 'sticker',
            type: '',
            height: 3,
            width: 2.5,
            price: 2,
            currentstock: 10,
            date: '',
        },
        101: {name: 'jewel design I',
            photo: printone,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        102: {name: 'hooded figure',
            photo: printtwo,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        103: {name: 'jewel design II',
            photo: printthree,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        104: {name: 'orange-red design',
            photo: printfour,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        105: {name: 'red-blue design',
            photo: printfive,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        106: {name: 'jewel design III',
            photo: printsix,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        107: {name: 'color tunnel',
            photo: printseven,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        108: {name: 'tropical leaves & butterfly',
            photo: printeight,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        109: {name: 'running gears',
            photo: printnine,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        110: {name: 'blue flowers',
            photo: printten,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        111: {name: 'red design',
            photo: printeleven,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        112: {name: 'purple rose',
            photo: printtwelve,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        113: {name: 'jug offering',
            photo: printthirteen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        114: {name: 'red-yellow design',
            photo: printfourteen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        115: {name: 'circle design',
            photo: printfifteen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        116: {name: 'yellow-red-green design',
            photo: printsixteen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        117: {name: 'multicolored flowers',
            photo: printseventeen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        118: {name: 'yellow-black design',
            photo: printeighteen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        119: {name: 'white-yellow-pink design',
            photo: printnineteen,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        120: {name: 'psychedelic sillouette',
            photo: printtwenty,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        121: {name: 'blue-pink design',
            photo: printtwentyone,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        122: {name: 'yellow-gray design',
            photo: printtwentytwo,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        123: {name: 'pink people',
            photo: printtwentythree,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        124: {name: 'yellow-green-red design',
            photo: printtwentyfour,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        125: {name: 'tree wave',
            photo: printtwentyfive,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        126: {name: 'cyborg',
            photo: printtwentysix,
            category: 'print',
            type: '',
            height: 6,
            width: 4,
            price: 3,
            currentstock: 10,
            date: '',
        },
        201: {
            name: 'red wave',
            photo: buttonone,
            category: 'button',
            type: '',
            height: 2,
            width: 2,
            price: 2,
            currentstock: 10,
            date: '',
        },
        202: {
            name: 'mystic blue woman',
            photo: buttontwo,
            category: 'button',
            type: '',
            height: 2,
            width: 2,
            price: 3,
            currentstock: 10,
            date: '',
        },
        203: {
            name: 'roses',
            photo: buttonthree,
            category: 'button',
            type: '',
            height: 2,
            width: 2,
            price: 4,
            currentstock: 10,
            date: '',
        },
        204: {
            name: 'multicolor design',
            photo: buttonfour,
            category: 'button',
            type: '',
            height: 2,
            width: 2,
            price: 4,
            currentstock: 10,
            date: '',
        },
        301: {
            name: 'ghoul growl',
            photo: pinone,
            category: 'pin',
            type: '',
            height: 1.25,
            width: 1,
            price: 2,
            currentstock: 10,
            date: '',
        },
        302: {
            name: 'cosmic gazing',
            photo: pintwo,
            category: 'pin',
            type: '',
            height: 1.25,
            width: 1,
            price: 2,
            currentstock: 10,
            date: '',
        },
        401: {
            name: 'astronaut',
            photo: magnetone,
            category: 'magnet',
            type: '',
            height: 3.6,
            width: 2.6,
            price: 4,
            currentstock: 10,
            date: '',
        },
        501: {
            name: 'blue person',
            photo: keychainone,
            category: 'keychain',
            type: '',
            height: 1.5,
            width: 1.5,
            price: 3,
            currentstock: 10,
            date: '',
        },
        601: {
            name: 'multicolor design',
            photo: coasterone,
            category: 'coaster',
            type: '',
            height: 3.7,
            width: 3.7,
            price: 3,
            currentstock: 10,
            date: '',
        },
        602: {
            name: 'dual dinosaurs',
            photo: coastertwo,
            category: 'coaster',
            type: '',
            height: 3.7,
            width: 3.7,
            price: 3,
            currentstock: 10,
            date: '',
        }
    }

    const [cart, setCart] = useState([])
    return (
        <div>
            <Router>
                <Navbar bg="dark" variant="dark" >
                    <Container fluid>
                        <Row>
                            <Col sm={2}>
                                <Image src={aliengazebologo} className='aliengazebologo' />
                            </Col>
                            <Col sm={8}>
                                <Row>
                                    <h1 className='text-center'><Link className='aliengazebotitle' to='/home'>[👽] ALIEN GAZEBO [🏯]</Link></h1>
                                </Row>
                                <Row>
                                    <Nav className="mr-auto">
                                    <Nav.Link className='navlink headernavlink'><Link className='headernavdropdown' to='/shop/items'>SHOP by ITEM</Link></Nav.Link>
                                    <Nav.Link className='navlink headernavlink'><Link className='headernavdropdown' to='/shop/bundle'>BUNDLE DEALS</Link></Nav.Link>
                                    <Nav.Link className='navlink headernavlink'><Link className='headernavdropdown' to='/contact'>CONTACT</Link></Nav.Link>
                                    <Nav.Link className='navlink headernavlink'><Link className='headernavdropdown' to='/cart'><FaShoppingCart /></Link></Nav.Link>
                                    <Nav.Link className='navlink headernavlink'><Link className='headernavdropdown' to='/checkout'><IoBagCheckOutline /></Link></Nav.Link>
                                    </Nav>
                                
                                </Row>
                                
                            </Col>
                        </Row>
                    </Container>
                </Navbar>

                <Switch>
                    <Route path='/checkout'>
                        <Checkout />
                    </Route>
                    <Route path='/cart'>
                        <Cart cart={cart} database={database} />
                    </Route>
                    <Route path='/shop/items'>
                        <Shop cart={cart} database={database} />
                    </Route>
                    <Route path='/contact'>
                        <Contact />
                    </Route>
                    <Route path='/home'>
                        <Home />
                    </Route>
                    <Route path='/'>
                        <Home />
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default Header;