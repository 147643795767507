import './Home.scss';
import menu from './menuphoto.png';


function Home () {
    return (
        <div>
            <img className='menuimg' src={menu}  alt='menu' />
        </div>
    )
}

export default Home;