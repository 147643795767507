import './Contact.scss';
import youtube from './youtubeicon.png';
import instagram from './instagramicon.png';
import facebook from './facebookicon.png';
import soundcloud from './soundcloudicon.png';
import { MdSettingsCell } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';


export default function Contact () {
    return (
        <div>
            <h1 className='name text-center'>Sergi Ferrer</h1>
            <p className='email text-center'><AiOutlineMail />: <a  className='email text-center' href='mailto:crazedfilms@icloud.com'>crazedfilms@icloud.com</a></p>
            <p className='cellphone text-center'><MdSettingsCell />Text: 631-848-7335</p>
            <div className='linksdiv'>
                <a href='https://www.youtube.com/channel/UCNRbZ9VvFYqduPQ5_fyDyMA' target='_blank' rel="noreferrer"><img className='youtubelink medialink' src={youtube} alt='youtubelink' /></a>
                <a href='https://instagram.com/thealiengazebo?igshid=13wkutrghyhys' target='_blank' rel="noreferrer"><img className='instagramlink medialink' src={instagram} alt='instagramlink' /></a>
                <a href='https://www.facebook.com/aliengazebo' target='_blank' rel="noreferrer"><img className='facebooklink medialink' src={facebook} alt='facebooklink' /></a>
                <a href='https://soundcloud.com/sergi-ferrer' target='_blank' rel="noreferrer"><img className='soundcloudlink medialink' src={soundcloud} alt='soundcloudlink' /></a>
            </div>
        </div>
    )
}
