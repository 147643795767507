import { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { FaCartArrowDown } from 'react-icons/fa';
import { FiMinusSquare, FiPlusSquare } from 'react-icons/fi';
import { BiArrowBack } from 'react-icons/bi';

export default function ItemPage (props) {
    const [quantity, setQuantity] = useState(1)
    function handleClick (event) {
        props.cart.push([props.itemindex, quantity])
    }

    function handleMinusQuantity (event) {
        //FIX THIS
        setOutjsx(genOutjsx())
    }

    function handlePlusQuantity (event) {
        //FIX THIS
        setOutjsx(genOutjsx())
    }


    function genOutjsx () {
        const startjsx = <Container>
            <Row>
                <Col>
                    <Image src={props.photo} className='itempagephoto' />
                </Col>
                <Col>
                    <div className='itemdescription'>
                        <p>Category: {props.itemdata['type'] + ' ' + props.itemdata['category']}</p>
                        <p>Description: {props.itemdata['name']}</p>
                        <p>Width: {props.itemdata['width']} inches</p>
                        <p>Height: {props.itemdata['height']} inches</p>
                        <p>Price: ${props.itemdata['price']}</p>
                        <p>Quantity: <Button variant='danger' onClick={handleMinusQuantity} className='minusbuttonpage'><FiMinusSquare /></Button><span className='quantitypage'>{quantity}</span><Button variant='danger' onClick={handlePlusQuantity} className='plusbuttonpage'><FiPlusSquare /></Button></p>
                        <Button variant='info' onClick={handleClick} className='cartbuttonpage'>
                            <FaCartArrowDown />
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
        return startjsx
    }
    
    const [outjsx, setOutjsx] = useState(genOutjsx())
    


    return (
        <div>
            {outjsx}
        </div>
    )
}